import {Controller} from "@hotwired/stimulus";
import i18n from "~/shared/i18n.js";
import Notify from "../shared/notifier";

export default class extends Controller {
  connect() {
    const urlLinkButton = document.querySelector(".trix-button--dialog");
    const urlInput = document.querySelector('.trix-dialog input[type="url"]');

    urlLinkButton.addEventListener("click", function () {
      const urlValue = urlInput.value;

      if (urlValue && !urlValue.startsWith("https://") && !urlValue.startsWith("http://")) {
        Notify(null, i18n.t("zamn_shared.url_invalid"));
      }
    });
  }
}
