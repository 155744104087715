import {Controller} from "@hotwired/stimulus";
import {DirectUpload} from "@rails/activestorage";
import i18n from "~/shared/i18n.js";

export default class extends Controller {
  static targets = ["fileInput", "submitButton"];

  connect() {
    this.uploadProgressBar = document.getElementById("upload-progress-bar");
    this.uploadProgressBarContainer = document.getElementById("upload-progress-bar-container");

    this.element.addEventListener("submit", this.handleFormSubmit.bind(this));
  }

  reset() {
    this.element.reset();
    this.element.querySelector(".form-errors").remove();
  }

  selectPdf(e) {
    if (e.target.files.length == 0) {
      return;
    }
    var file = e.target.files[0];
    const sizeLimit = 50000000; // 50 megabyte
    if (file.size >= sizeLimit) {
      alert(i18n.t("zamn_shared.max_upload_50_mb"));
      return;
    }
    var nextSibling = e.target.nextElementSibling;
    nextSibling.style.overflow = "hidden";
    nextSibling.style.whiteSpace = "nowrap";
    nextSibling.style.textOverflow = "ellipsis";
    nextSibling.innerText = file.name;
  }

  selectImage(e) {
    if (e.target.files.length == 0) {
      return;
    }
    var file = e.target.files[0];
    const sizeLimit = 2000000; // 2 megabyte
    if (file.size >= sizeLimit) {
      alert(i18n.t("zamn_shared.max_upload_2_mb"));
      return;
    }
    var nextSibling = e.target.nextElementSibling;
    nextSibling.style.overflow = "hidden";
    nextSibling.style.whiteSpace = "nowrap";
    nextSibling.style.textOverflow = "ellipsis";
    nextSibling.innerText = file.name;
  }

  handleFormSubmit() {
    const fileInputs = this.fileInputTargets;
    if (fileInputs.length !== 0) {
      this.uploadFiles(fileInputs);
    }
  }

  uploadFiles(fileInputs) {
    fileInputs.forEach((input) => {
      const file = input.files[0];
      if (file) {
        this.uploadFile(file, input);
      }
    });
  }

  uploadFile(file, input) {
    const upload = new DirectUpload(file, input.dataset.directUploadUrl, this);

    upload.create((error, blob) => {
      if (error) {
        alert(error);
      } else {
        this.appendHiddenInput(input, blob.signed_id);
      }
    });
  }

  appendHiddenInput(input, signedId) {
    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = input.name;
    hiddenInput.value = signedId;
    this.element.appendChild(hiddenInput);
  }

  directUploadWillStoreFileWithXHR(request) {
    this.showProgressBar();
    request.upload.addEventListener("progress", (event) => {
      const progress = (event.loaded / event.total) * 100;
      this.updateProgressBar(progress);
    });
  }

  showProgressBar() {
    this.submitButtonTarget.disabled = true;
    this.uploadProgressBarContainer.classList.remove("hidden");
  }

  updateProgressBar(progress) {
    this.uploadProgressBar.style.width = `${progress}%`;
  }

  hideProgressBar() {
    this.uploadProgressBarContainer.classList.add("hidden");
    this.uploadProgressBar.style.width = "0";
  }
}
