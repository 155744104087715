import {Controller} from "@hotwired/stimulus";
import i18n from "~/shared/i18n.js";
import Notify from "../shared/notifier";

export default class extends Controller {
  static targets = ["fieldType", "options", "student", "optionsContainer"];
  static values = {
    maxOptions: {type: Number, default: 10},
    minOptionLength: {type: Number, default: 1},
    maxOptionLength: {type: Number, default: 50},
  };

  initialize() {
    this.existingOptions = new Set();
    this.collectExistingOptions();
  }

  collectExistingOptions() {
    const existingInputs = this.optionsContainerTarget.querySelectorAll(
      'input[name="custom_user_field[options][]"]'
    );
    existingInputs.forEach((input) => {
      this.existingOptions.add(input.value.toLowerCase());
    });
  }

  optionsTargetConnected() {
    this.toggleOptions();
  }

  toggleOptions() {
    if (this.fieldTypeTarget.value === "simple_select") {
      this.optionsTarget.style.display = "block";
    } else {
      this.optionsTarget.style.display = "none";
    }
  }

  addOption() {
    const optionsContainer = this.optionsContainerTarget;
    const newOptionValue = this.getNewOptionValue();

    try {
      if (this.validateOption(newOptionValue)) {
        const index = optionsContainer.childElementCount;
        const optionRow = this.createOptionRow(index, newOptionValue);

        this.existingOptions.add(newOptionValue.toLowerCase());
        optionsContainer.appendChild(optionRow);
        this.clearNewOptionInput();
      }
    } catch (error) {
      this.showError(error.message);
    }
  }

  validateOption(value) {
    if (!value) {
      throw new Error(i18n.t("zamn_shared.custom_user_field.option_empty"));
    }
    if (value.length < this.minOptionLengthValue) {
      throw new Error(i18n.t("zamn_shared.custom_user_field.option_min_length", { length: this.minOptionLengthValue }));
    }
    if (value.length > this.maxOptionLengthValue) {
      throw new Error(i18n.t("zamn_shared.custom_user_field.option_max_length", { length: this.maxOptionLengthValue }));
    }
    if (this.existingOptions.has(value.toLowerCase())) {
      throw new Error(i18n.t("zamn_shared.custom_user_field.option_exists"));
    }
    if (this.optionsContainerTarget.childElementCount >= this.maxOptionsValue) {
      throw new Error(i18n.t("zamn_shared.custom_user_field.max_options", { max: this.maxOptionsValue }));
    }
    return true;
  }

  showError(message) {
    Notify(null, message);
  }

  getNewOptionValue() {
    const newOptionInput = document.getElementById("new-option-input");
    return newOptionInput.value.trim();
  }

  clearNewOptionInput() {
    document.getElementById("new-option-input").value = "";
  }

  createOptionRow(index, value) {
    const optionRow = document.createElement("div");
    optionRow.classList.add(
      "w-full",
      "flex",
      "justify-between",
      "items-center",
      "leading-snug",
      "mt-4",
      "flex-col",
      "sm:flex-row"
    );
    optionRow.setAttribute("data-index", index);
    optionRow.setAttribute("role", "group");
    optionRow.setAttribute("aria-label", `Option ${index + 1}`);

    const deleteButton = this.createDeleteButton();
    const input = this.createOptionInput(index, value);

    optionRow.appendChild(input);
    optionRow.appendChild(deleteButton);

    return optionRow;
  }

  createDeleteButton() {
    const button = document.createElement("button");
    button.type = "button";
    button.classList.add("ml-3", "text-red-600");
    button.setAttribute("data-action", "click->custom-user-field#removeOption");
    button.setAttribute("aria-label", "Delete option");
    button.innerHTML = this.trashIcon();
    return button;
  }

  createOptionInput(index, value) {
    const input = document.createElement("input");
    input.type = "text";
    input.className =
      "w-full border-none focus:ring-0 focus:outline-none hover:border-none active:border-none shadow-none";
    input.name = "custom_user_field[options][]";
    input.value = value;
    input.id = `option-${index}`;
    input.setAttribute("aria-label", `Option ${index + 1}`);
    return input;
  }

  removeOption(event) {
    const button = event.currentTarget;
    const optionRow = button.closest("div[data-index]");
    const optionValue = optionRow.querySelector("input").value.toLowerCase();
    this.existingOptions.delete(optionValue);
    optionRow.remove();
  }

  trashIcon() {
    return '<i class="fa-solid fa-trash"></i>';
  }
}
