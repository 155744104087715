import {Controller} from "@hotwired/stimulus";
import i18n from "~/shared/i18n.js";

export default class extends Controller {
  validate(e) {
    const allowed_file_types = ["application/pdf", "image/jpeg", "image/png"];
    if (!allowed_file_types.includes(e.target.files[0].type)) {
      e.target.parentElement.classList.add("border", "border-danger");
      e.target.parentElement.innerHTML = i18n.t("zamn_shared.invalid_exten");
    }
  }
}
