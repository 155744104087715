import {Controller} from "@hotwired/stimulus";
import {get} from "@rails/request.js";

// Connects to data-controller="product"
export default class extends Controller {
  static targets = ["paid", "option"];

  onProductTypeChange(e) {
    let paymentable = e.target.value;
    get(`/school/paymentables/${paymentable.toLowerCase()}s/new?paymentable_type=${paymentable}`, {
      responseKind: "turbo-stream",
    });
  }

  toggle(event) {
    this.optionTargets.forEach((input) => {
      input.disabled = !event.currentTarget.checked;
    });
  }
}
