import {Controller} from "@hotwired/stimulus";
import {openModal} from "../shared/modal.js";
import {debounceSearch} from "../shared/utils/debounce_search";

export default class extends Controller {
  static targets = [
    "overviewCard",
    "attentionCard",
    "pollingCard",
    "overviewTable",
    "attentionTable",
    "pollingTable",
    "searchForm",
  ];

  connect() {
    this.attentionTableTarget.hidden = true;
    this.pollingTableTarget.hidden = true;
    this.overviewCardTarget.classList.add("border", "border-primary-500");
  }

  showOverview(e) {
    this.attentionTableTarget.hidden = true;
    this.pollingTableTarget.hidden = true;
    this.overviewTableTarget.hidden = false;
    this.overviewCardTarget.classList.add("border", "border-primary-500");
    this.attentionCardTarget.classList.remove("border", "border-primary-500");
    this.pollingCardTarget.classList.remove("border", "border-primary-500");
  }

  showAttention(e) {
    this.attentionTableTarget.hidden = false;
    this.pollingTableTarget.hidden = true;
    this.overviewTableTarget.hidden = true;
    this.overviewCardTarget.classList.remove("border", "border-primary-500");
    this.pollingCardTarget.classList.remove("border", "border-primary-500");
    this.attentionCardTarget.classList.add("border", "border-primary-500");
  }

  showPolling(e) {
    this.attentionTableTarget.hidden = true;
    this.pollingTableTarget.hidden = false;
    this.overviewTableTarget.hidden = true;
    this.overviewCardTarget.classList.remove("border", "border-primary-500");
    this.attentionCardTarget.classList.remove("border", "border-primary-500");
    this.pollingCardTarget.classList.add("border", "border-primary-500");
  }

  openUserInfo(e) {
    e.preventDefault();
    const attendeeId = e.currentTarget.getAttribute("id");
    openModal(`attendee${attendeeId}`);
  }

  onSearchChange() {
    this.timeout = debounceSearch(this.timeout, this.submitSearchForm.bind(this));
  }

  submitSearchForm() {
    this.searchFormTarget.requestSubmit();
  }
}
