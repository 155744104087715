import {Controller} from "@hotwired/stimulus";
import i18n from "~/shared/i18n.js";

export default class extends Controller {
  reset() {
    this.element.reset();
    this.element.querySelector(".form-errors").remove();
  }

  selectVideo(e) {
    if (e.target.files.length == 0) {
      return;
    }
    var file = e.target.files[0];
    const sizeLimit = 100000000; // 100 megabyte
    if (file.size >= sizeLimit) {
      alert(i18n.t("zamn_shared.max_upload_100_mb"));
      return;
    }
    var nextSibling = e.target.nextElementSibling;
    nextSibling.style.overflow = "hidden";
    nextSibling.style.whiteSpace = "nowrap";
    nextSibling.style.textOverflow = "ellipsis";
    nextSibling.innerText = file.name;
  }
}
