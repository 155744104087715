import {Controller} from "@hotwired/stimulus";
import i18n from "~/shared/i18n.js";
import Notify from "../shared/notifier";

export default class extends Controller {
  static targets = ["noneZoomFields", "accessCode", "moderatorAccessCode", "submit"];

  updateFormfields(e) {
    this.noneZoomFieldsTargets.forEach((element) => {
      element.hidden = e.currentTarget.value === "zoom";

      const fields = [
        "file-upload",
        "room_description",
        "room_access_code",
        "room_moderator_access_code",
      ];
      fields.forEach((element) => {
        document.getElementById(element).disabled = e.currentTarget.value === "zoom";
      });
    });
  }

  validateName(e) {
    const ele = e.target;
    if (ele.value.length > 128) {
      Notify(null, i18n.t("zamn_shared.name_long"));
      this.submitTarget.setAttribute("disabled", true);
    } else if (ele.value.length < 2) {
      Notify(null, i18n.t("zamn_shared.name_min_length"));
      this.submitTarget.setAttribute("disabled", true);
    } else {
      this.submitTarget.removeAttribute("disabled");
    }
  }

  generateAccessCode(e) {
    const accessCodeLength = 6;
    var validCharacters = "0123456789";
    var accessCode = "";

    for (var i = 0; i < accessCodeLength; i++) {
      accessCode += validCharacters.charAt(Math.floor(Math.random() * validCharacters.length));
    }

    this.accessCodeTarget.value = accessCode;
  }

  generateModeratorAccessCode() {
    const accessCodeLength = 6;
    var validCharacters = "abcdefghijklmopqrstuvwxyz";
    var accessCode = "";

    for (var i = 0; i < accessCodeLength; i++) {
      accessCode += validCharacters.charAt(Math.floor(Math.random() * validCharacters.length));
    }

    this.moderatorAccessCodeTarget.value = accessCode;
  }

  selectBanner(e) {
    if (e.target.files.length == 0) {
      return;
    }
    var file = e.target.files[0];
    const sizeLimit = 2000000; // 2 megabyte
    if (file.size >= sizeLimit) {
      alert(i18n.t("zamn_shared.max_upload_2_mb"));
      return;
    }
    var nextSibling = e.target.nextElementSibling;
    nextSibling.style.overflow = "hidden";
    nextSibling.style.whiteSpace = "nowrap";
    nextSibling.style.textOverflow = "ellipsis";
    nextSibling.innerText = file.name;
  }
}
